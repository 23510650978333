import { request } from './request'
import qs from 'qs'
// 基础设置
// ---------------------------------
// /------------------------品牌管理-------------------/
// 品牌管理 列表数据
export function getBasisBrandList (params) {
  return request({
    url: '/admin/brand',
    method: 'GET',
    params
  })
}
// 品牌管理 操作 数据
export function metBasisBrandData (method, datas) {
  return request({
    url: '/admin/brand',
    method,
    data: qs.stringify(datas)
  })
}
// 品牌 状态变更
export function putStatusBasisBrandData (method, datas) {
  return request({
    url: '/admin/brand/status',
    method,
    data: qs.stringify(datas)
  })
}

// /------------------------商品分类-------------------/
// 商品分类管理 列表数据
export function getBasisGoodsList (params) {
  return request({
    url: '/admin/goods-category',
    method: 'GET',
    params
  })
}
// 商品分类管理 操作 数据
export function metBasisGoodsData (method, datas) {
  return request({
    url: '/admin/goods-category',
    method,
    data: qs.stringify(datas)
  })
}

// /------------------------旅游分类-------------------/
// 旅游分类管理 列表数据
export function getBasisTourList (params) {
  return request({
    url: '/admin/tour-category',
    method: 'GET',
    params
  })
}

// 旅游分类管理 操作 数据
export function metBasisTourList (method, datas) {
  return request({
    url: '/admin/tour-category',
    method,
    data: qs.stringify(datas)
  })
}

// /------------------------景点元素-------------------/
// 景点元素管理 列表数据
export function getScenicList (params) {
  return request({
    url: '/admin/tourist-attraction',
    method: 'GET',
    params
  })
}

//景点元素操作
export function addScenicList (method, datas) {
  return request({
    url: '/admin/tourist-attraction',
    method,
    data: qs.stringify(datas)
  })
}

//景点元素详情
export function addScenicListDetail (params) {
  return request({
    url: '/admin/tourist-attraction/show',
    method:'GET',
    params
  })
}

//景点元素状态变更
export function putStatusScenicListData (method, datas) {
  return request({
    url: '/admin/tourist-attraction/change-status',
    method,
    data: qs.stringify(datas)
  })
}
// /------------------------特权分类-------------------/
// 特权分类管理 列表数据
export function getPrivilege (params) {
  return request({
    url: '/admin/privilege-type',
    method: 'GET',
    params
  })
}

//特权分类操作
export function addPrivilege (method, datas) {
  return request({
    url: '/admin/privilege-type',
    method,
    data: qs.stringify(datas)
  })
}
// /------------------------卡券管理-------------------/
// 卡券管理  获取列表数据
export function getCardList (params) {
  return request({
    url: '/admin/type/card',
    method: 'GET',
    params
  })
}

//卡券管理操作
export function metCardList (method, datas) {
  return request({
    url: '/admin/type/card',
    method,
    data: qs.stringify(datas)
  })
}

// /------------------------分润管理-------------------/
// 分润管理  获取列表数据
export function getProfitList (params) {
  return request({
    url: '/admin/distribution',
    method: 'GET',
    params
  })
}

//分润管理操作
export function metProfitList (method, datas) {
  return request({
    url: '/admin/distribution',
    method,
    data: qs.stringify(datas)
  })
}

//分润状态变更
export function putStatusProfitListData (method, datas) {
  return request({
    url: '/admin/distribution/change-status',
    method,
    data: qs.stringify(datas)
  })
}

// /------------------------ 旅游区域-------------------/
// 旅游区域  获取列表数据
export function getTourAreaList (params) {
  return request({
    url: '/admin/tour-area',
    method: 'GET',
    params
  })
}

// 旅游区域管理 操作 数据
export function metTourAreaList (method, datas) {
  return request({
    url: '/admin/tour-area',
    method,
    data: qs.stringify(datas)
  })
}
// /------------------------ 房型主题-------------------/
export function Add_PUTroomTopic (method,params){
  return request({
    url:'admin/room_theme',
    method,
    params
  })
}

export function GetroomTopic (params){
  return request({
    url:'admin/room_theme',
    method:'GET',
    params
  })
}